import { useSubscription } from '@apollo/client';
import {
  FeedScope,
  PostCardFragmentFragment,
  RepostCardFragmentFragment,
  StreamPostCardFragmentFragment,
} from '@/__generated__/graphql.ts';
import { gql } from '@/__generated__/index.ts';
import { updateFeedCache } from '@/apollo-cache-utils/update-feed-cache.ts';
import { PostCardFragment } from '@/components/post-card/post-card-fragment';
import { RepostCardFragment } from '@/components/post-card/repost-card-fragment.ts';
import { StreamPostCardFragment } from '@/components/stream-post-card/stream-post-card-fragment.ts';
import { TournamentPostCardFragmentFragment } from '@/__generated__/graphql.ts';
import { TournamentPostCardFragment } from '@/components/tournament-post-card/tournament-post-card-fragment.ts';

const FeedSubscription = gql(`
subscription OnFeedUpdated($feedScope: FeedScope!) {
  feedUpdates(feedScope: $feedScope) {
    __typename
    post {
      ... on RegularPost {
        ...PostCardFragment
      }
      ... on RePost {
       ...RepostCardFragment
      }
      ... on TournamentPost {
       ...TournamentPostCardFragment
      }
      ... on StreamPost {
       ...StreamPostCardFragment
      }
    }
  }
}
`);

export const FollowingSubscription = ({
  feedScope,
  onData,
  refetchQuery,
}: {
  feedScope: FeedScope;
  onData: (
    post:
      | PostCardFragmentFragment
      | RepostCardFragmentFragment
      | TournamentPostCardFragmentFragment
      | StreamPostCardFragmentFragment,
  ) => void;
  refetchQuery: () => void;
}) => {
  useSubscription(FeedSubscription, {
    variables: {
      feedScope,
    },
    onData({ data, client }) {
      const post = data.data?.feedUpdates?.post;
      if (post) {
        onData(post);
        client.cache.modify({
          fields: {
            feed(existing, { storeFieldName }) {
              if (storeFieldName.includes(feedScope)) {
                const newPostRef =
                  post.__typename === 'RePost'
                    ? client.cache.writeFragment({
                        fragment: RepostCardFragment,
                        fragmentName: 'RepostCardFragment',
                        data: post,
                      })
                    : post.__typename === 'RegularPost'
                      ? client.cache.writeFragment({
                          fragment: PostCardFragment,
                          fragmentName: 'PostCardFragment',
                          data: post,
                        })
                      : post.__typename === 'StreamPost'
                        ? client.cache.writeFragment({
                            fragment: StreamPostCardFragment,
                            fragmentName: 'StreamPostCardFragment',
                            data: post,
                          })
                        : post.__typename === 'TournamentPost'
                          ? client.cache.writeFragment({
                              fragment: TournamentPostCardFragment,
                              fragmentName: 'TournamentPostCardFragment',
                              data: post,
                            })
                          : undefined;

                return updateFeedCache(existing, newPostRef);
              }

              return existing;
            },
          },
        });
      }
    },
    errorPolicy: 'all',
    onError() {
      refetchQuery();
    },
  });

  return null;
};
